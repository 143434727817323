.bank {
  &__wrap {
    display: flex;
    @include media-sm {
      flex-direction: column;
    }
  }
  &__section {
    max-width: 50%;
    flex-basis: 50%;
    min-height: 100%;
    padding: 0 38px 39px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-left {
      position: relative;
      padding-left: 0;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 39px;
        width: 1px;
        background: $gray-2;
      }
      @include media-sm {
        &:after {
          display: none;
        }
      }
      @include media-mobile {
        margin-bottom: 20px;
        padding: 0;
      }
    }
    &-right {
      padding-right: 0;
      min-height: 100%;
      @include media-mobile {
        &:after {
          display: none;
        }
        padding: 0;
      }
    }
    &--modal-left {
      background-color: $gray-3;
      padding-top: 35px;
    }
    &--modal-right {
      padding-top: 35px;
      .bank__sub-section {
        &--modal-amount {
          flex: 0;
        }
      }
    }
    @include media-sm {
      min-height: initial;
      max-width: 100%;
      padding: 0;
    }
  }
  &__cash {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__sub-section {
    width: 100%;
    margin-bottom: var(--md-resize);
    &:last-child {
      margin-bottom: 0;
    }
    &--descr {
      margin-bottom: 32px;
      p {
        @include field-font__h5;
      }
      @include media-mobile{
        order: 2;
        margin-top: 30px;
      }
    }
    &--amount {
      flex: 0;
      @include media-mobile {
        order: 1;
      }
    }
    &--amount-withdraw {
      flex: 0;
      p {

        @include media-mobile {
          font-size: 16px;
          font-weight: $font-weight-alt;
        }
      }
      .btn-paid {
        width: 100%;
      }
    }
  }
  &__sub-section-withdraw {
    flex: 1
  }
  &__title {
    color: $black-1;
    @include field-font__h4;
    font-weight: $font-weight-alt;
    padding-bottom: 25px;
    &-details {
      padding-bottom: 0;
    }
    &--border {
      border-bottom: 1px solid $gray-2;
    }
    &--modal {
      margin-bottom: 30px;

      @include media-sm {
        margin-bottom: 10px;
      }
    }
    @include media-mobile {
      font-size: 16px;
      padding-bottom: 15px;
    }
  }
  &__amount {
    margin-bottom: var(--md-resize);
  }
  &__text {
    margin-bottom: 10px;
    @include field-font__h4;
    &.select-amount {
      p {
        font-size: 14px;
      }
      strong {
        @include field-font__h4;
        font-weight: 700;
      }
    }

    label {
      display: none;
    }
  }
  &__logo {
    display: block;
    width: 70%;
    @include media-sm {
      display: block;
      width: 50%;
      margin: auto;
    }
    @include media-mobile {
      display: none;
    }
  }
}
.bank__sub-section {
  .field-error {
    .field-helperError {
      margin-top: 0;
      padding-top: 18px;
      font-size: 16px;
    }
  }
  .field-inputValues .input-values {
    margin-bottom: 20px;
    grid-template-columns: repeat(3, minmax(50px, 1fr));
    button:hover {
      border: 2px solid $green-4;
    }
  }
  .field-input--primary {
    min-height: 210px;
    @include media-mobile {
      min-height: 180px;
    }
  }
  .field-input--primary p.field-helper {
    order: 2;
    @include media-mobile {
      margin-bottom: 0;
    }
  }
  .field-input--primary .field-label, .field-input--primary legend {
    order: 1;
    @include field-font__h4;
    opacity: .7;
    @include media-mobile {
      display: none;
    }
  }
  .field-input--primary .input-root {
    order: 2;
    margin-top: 0;
  }
  .transactions-paginator {
    margin-bottom: 0;
  }
  .btn-paid {
    width: 100%;
  }
  .iban-field {
    .field-input--primary .field-label {
      @include field-font__h4;
      color: $gray-2;
    }
    .field-input--primary.field-disabled .input-root {
      border: 1px solid #ccc;
      background-color: $gray-8;
      .input-inputSingleline {
        color: black;
        -webkit-text-fill-color: black;
      }
    }
  }
  &--ideal h3 {
    @include field-font__h4;
    font-weight: bold;
  }
}
.btn-deposit {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  border: 1px solid #ccc;
  border-radius: 0;
  font-size: 18px;
  font-weight: bold;
  margin: var(--md-resize) 0 35px;
  padding: 10px;
  cursor: pointer;
  span {
    &.active {
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: 45px;
    margin-top: 5px;
    fill: $green-4;
    &.active {
      fill: $green-4;
    }
    @include media-mobile {
      margin-top: 10px;
    }
  }
  &.active {
    border: 2px solid #63ce18;
    color: $green-1;
  }
  &:first-of-type {
    margin-top: 0;
  }
  @include media-mobile {
    width: 70%;
    height: 125px;
    margin: 0 auto 15px;
  }
}
.withdrawal-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border: 1px solid #ccc;
  color: $green-1;
  font-size: 18px;
  font-weight: $font-weight-alt;
  padding: 10px 20px 20px;
  margin-bottom: var(--md-resize);
  cursor: pointer;

  span {
    padding-bottom: 15px;
  }
  img {
    width: 100%;
    max-height: 43px;
  }
  &.active {
    border: 2px solid #63ce18;
    color: $green-1;
  }
}
.modal-root .modal-paper {
  justify-content: stretch;
  .modalContentWrapper .bank__section {
    max-width: 100%;
    padding: 50px;

    @include media-sm {
      padding: 30px;
    }
  }
}
