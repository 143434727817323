.deposit-form {
  .deposit-content {
    display: flex;
    flex-direction: column;

    &--modal {
      padding: 30px;
    }

    .field-helperError {
      font-size: 16px;
      padding: 8px 0;
    }
  }

  &__placeholder {
    height: 35px;
  }

  &__limit-reached-wrapper {
    padding: 20px 40px;

    h4 {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .markdown-root {
      p {
        font-size: 18px;
        font-weight: 400;
      }
    }

    img {
      display: block;
      margin: 29px auto 46px;
    }

    .button-primary {
      display: flex;
      margin: 46px auto 29px;
      width: fit-content;
      text-transform: uppercase;
    }
  }
  &__footer-link {
    display: block;
    margin-top: 40px;
    font-size: 18px;
  }
  .button-wrapper .button-root {
    margin: 0;
    width: 100%;
  }
  &__field {
    &--notification {
      color: $green-1;
      margin-bottom: 10px;
    }
    &--card-number + &--select-bonus {
      margin-top: 60px;
    }
    &--deduct, &--receive {
      margin-top: 15px;
    }
    &--amount {
      .input-before {
        padding-right: 2px;
      }
      &.field-empty {
        .input-before {
          display: inline;
        }
      }
      .input-root {
        padding-left: 15px;
      }
      &.field-success {
        .input-root {
          border: 2px solid $green-4;
        }
      }
    }
  }
  &__limits {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-4;

    .gridItem-root {
      text-align: center;
      font-weight: 400;
    }
    @include media-sm{
      padding-bottom: 30px;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(primary, main);

    strong {
      font-size: 20px;
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
  &__ukgc-message {
    text-align: justify;
  }
  &__fields-title {
    p {
      margin: 0;
    }
  }
  &__fields {
    .gridItem-root[class*='deposit-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }
    .gridItem-root[class*='deposit-form__field--customer_cc_number']:not(:only-child) {
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
  &__amounts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    margin-bottom: 15px;
    transition: all 0.3s;

    .amount-value {
      width: 100%;
      height: 35px;

      button {
        width: 100%;
        min-width: initial;
        padding: 0 5px;
        border-radius: 0;
        border: 1px solid #999999;
        background: #ffffff;

        &:hover {
          border: 2px solid #368b14;
          background: #ffffff;
        }
      }
    }
  }

  .amount-block {
    width: 100%;
    height: 35px;
    display: flex;
    gap: 10px;
    margin: 8px 0;

    &__amount {
      flex: 1;
      height: 35px;
    }

    &__method {
      .select-selectMenu {
        padding: 0;
        border-color: #999999;

        img {
          height: 35px;
        }
      }

      .select-icon {
        display: none;
      }

      .method-icon {
        height: 100%;
      }
    }
  }

  .termsConditions-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .markdown-root a, p {
      font-size: 14px;
      font-weight: inherit !important;
    }
  }

  .notification-center {
    text-align: center;
  }

  .deposit-form__footer-link {
    a {
      @include link--root;
      @include link--color('secondary');
      @include link--hover('secondary');
    }
  }
  .notification-root + .iban-check-form__wrapper {
    margin-top: 20px;
  }
  &__notifications {
    .notification-root {
      width: auto;
      margin: 20px 25px;

      &:first-child {
        margin: 50px 25px 20px;
      }
    }
  }
  &__missed_limits {
    padding: var(--lg-resize);
  }

  &__declarations-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  &__declarations-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
.deposit-form__frame,
.resume-form__frame {
  width: 100%;
  height: 90vh;
  border: none;

  // cubits
  &.deposit-form__frame_provider_410004 {
    margin: 5px 0;
    height: 560px;
  }
  // voucher
  &.deposit-form__frame_provider_150001 {
    display: none;

    & + .deposit-step-back {
      display: none !important;
    }
  }
  // trustly
  &_provider_300001,
  &_provider_300002 {
    height: 450px;

    .tablet &,
    .mobile & {
      background-color: color(primary, light);
    }
  }

  // zimpler
  &_provider_440001 {
    height: 580px;

    .tablet &,
    .mobile & {
      height: 500px;
    }
  }

  &--authentication {
    display: none;

    &_visible {
      height: 100%;
      width: 100%;
      border: none;
      min-height: 400px;
    }
  }
}
.payment-select-field,
.payment-method-field,
.deposit-form__field--bank_issuer_id {
  .select-select {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;

    span {
      display: inline-block;
    }
  }
}
.payment-method-field__img,
img.payment-select-field__img {
  max-height: 30px;
  object-fit: contain;
}
div.payment-select-field__img {
  margin-right: 5px;
}
.payment-method-field__img-country {
  width: auto;
  max-height: 30px;
  margin-right: 5px;
}
.deposit-summary {
  margin-top: 20px;

  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__turnover-title {
    p {
      margin: 0;
    }
    em {
      font-size: 12px;
      font-style: normal;
      color: color(field--primary, hint__text);
    }
  }
  &__deduct,
  &__receive {
    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      margin-top: 15px;

      p {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
  &__free-spins {
    font-weight: 700;
    color: color(accent, secondary);
  }
  &__total.group-root {
    margin-top: 40px;
    font-weight: 700;
  }
}
.deposit-limit-confirmation {
  margin-top: 30px;
  text-align: center;
}
.deposit-step-back,
.resume-step-back {
  position: absolute;
  top: 40px;
  left: 80px;
  display: flex;
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-size: 14px;
  border: 0;
  background: none;
  overflow: hidden;
  transition: all 0.2s;

  @include media-sm {
    left: 15px;
  }
  .button-label {
    transform: translateY(-100%);
    transition: transform .2s;
  }
  &::before {
    @include svg-icon;
    content: '\0062';
    margin-right: 5px;
    font-size: 15px;
    transform: rotate(180deg);
  }
  &:hover {
    color: color(secondary, main);
    background: none;

    .button-label {
      transform: translateY(0);
    }
  }
}
.supported-currency-field .field-helperHint,
.exchange {
  font-size: 12px;
  color: color(field--primary, hint__text);
}

/** ePro */
.deposit-form--370001 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--customer_cc_name,
      &--customer_cc_surname {
        max-width: 45%;
        flex-basis: 45%;
      }
      &--customer_cc_expmo,
      &--customer_cc_expyr,
      &--customer_cc_cvc {
        max-width: 30%;
        flex-basis: 30%;
      }
    }
  }
}
/* **/

/** Neteller */
.deposit-form--110002 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--netellerAccountId {
        max-width: 65%;
        flex-basis: 65%;
      }
      &--secureId {
        max-width: 25%;
        flex-basis: 25%;
      }
    }
  }
}
/* **/

// Cryptopay
.deposit-form--910002,
.deposit-form--910003 {
  display: flex;
  flex-direction: column;
  flex: none;
  height: auto;

  .field-payAmount--910002,
  .field-address--910002 {
    align-self: flex-end;
    width: 70%;

    @include media-sm {
      order: 2;
      width: 100%;
      margin-top: 0;
    }
  }
  .field-payAmount--910002,
  .field-address--910002,
  .field-address--910003 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(field--primary, text--normal);
      }
    }
    .button-default::after {
      display: none;
    }
  }
  .field-uri--910002 {
    position: absolute;
    width: inherit;
    margin-top: 95px;

    @include media-sm {
      position: relative;
      order: 1;
      margin-top: 0;
    }
    label {
      display: none;
    }
    .button-primary {
      margin-top: 10px;
    }
  }
  .field-expiresAt--910002 {
    padding-top: 15px;

    @include media-sm {
      order: 3;
      padding-top: 0;
    }
    .expired::before {
      background: color(error);
    }
    label {
      right: 0;
      text-align: center;
    }
    input[type="text"] {
      text-align: center;
    }
  }
}

.field-qrCode--950001 {
  label {
    display: none;
  }
  .button-primary {
    margin-top: 10px;
  }
}

// OXXOPAY, SendAbono
.deposit-form--400002,
.deposit-form--1210001 {
  flex-direction: column;

  .field-reference--400002,
  .field-stpAbonosAccountNumber--1210001 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(field--primary, text--normal);
      }
    }
    .button-default::after {
      display: none;
    }
  }
}

// Sr.Pago
.deposit-form--1010001 {
  > iframe + .deposit-form__circular-progress {
    position: absolute; // relative to .modal-paper
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.deposit-form-campaigns {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__title {
    font-weight: 700;
  }
  &__item {
    width: 100%;
    height: 60px;
    white-space: initial;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #999999;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    &__description {
      width: 100%;
      text-align: left;
      margin: 10px 0;
      padding-left: 20px;
    }

    &__image {
      height: 100%;
      width: 102px;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
      }

      @include media-mobile {
        margin: 0 auto;
      }
    }

    &__checked {
      height: 100%;
      width: 102px;
      padding: 4px;
      display: flex;
      justify-content: center;
      background: #0A7D06;

      .image-checked {
        position: relative;
        height: 100%;
        width: 56px;
        background: white;
        border-radius: 50%;

        &::before {
          font-family: "Glyphter-custom" !important;
          position: absolute;
          content:'\0027';
          color: #0A7D06;
          top: 50%;
          left: 50%;
          font-size: 38px;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__link {
      a {
        font-weight: 700;
        color: $orange-3;
      }
    }

  }
  .active {
    background: #08AC00;
    border-color: #08AC00;
    color: white;
  }

  .noAvailable {
    filter: grayscale(1);
  }
}
